import Pusher from "pusher-js";

// Instancia única de Pusher
let pusher = null;

/**
 * Inicializa Pusher con configuración.
 */
export function initializePusher() {
    pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
    });
    console.log("Pusher initialized");
}

/**
 * Suscribirse a un canal.
 * @param {String} channelName - Nombre del canal.
 * @param {String} eventName - Nombre del evento.
 * @param {Function} callback - Función de callback para manejar el evento.
 */
export function subscribeToChannel(channelName, eventName, callback) {
    const channel = pusher.subscribe(channelName);
    channel.bind(eventName, callback);
    console.log(`Subscribed to channel: ${channelName}, event: ${eventName}`);
}

/**
 * Cancelar la suscripción a un canal.
 * @param {String} channelName - Nombre del canal.
 */
export function unsubscribeFromChannel(channelName) {
    pusher.unsubscribe(channelName);
    console.log(`Unsubscribed from channel: ${channelName}`);
}

/**
 * Detener Pusher.
 */
export function disconnectPusher() {
    if (pusher) {
        pusher.disconnect();
        console.log("Pusher disconnected");
    }
}
